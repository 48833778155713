<template>
  <a-modal
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    width="80%"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-row>
      <a-col :span="18">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="经销商" prop="dealerName" v-if="handle != 'check' && handle != 'checkCode'">
            <SelectDealerModal
              :value.sync="rowData.dealerName"
              :officeId="rowData.firmId"
              @select="toSelectConsumer"
            ></SelectDealerModal>
          </a-form-model-item>
          <a-form-model-item label="经销商" prop="dealerName" v-else>
            <a-input v-model="rowData.dealerName" :disabled="handle == 'check' || handle == 'checkCode'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="信用额度名称" prop="name">
            <a-input v-model="rowData.name" :disabled="handle == 'check' || handle == 'checkCode'" placeholder="请输入信用额度名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="有效日期" prop="validityStart">
            <RangePicker
              :startTime.sync="rowData.validityStart"
              :endTime.sync="rowData.validityEnd"
              :disabled-date="disabledDate"
              :disabled="handle == 'check' || handle == 'checkCode'"
            ></RangePicker>
          </a-form-model-item>
          <a-form-model-item label="申请文件" prop="fileUrl">
            <QiniuUpload type="image" :disabled="handle == 'check' || handle == 'checkCode'" :value.sync="rowData.fileUrl"></QiniuUpload>
          </a-form-model-item>
          <a-form-model-item label="额度商品类型" prop="limitType">
            <a-radio-group v-model="rowData.limitType" :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'" @change="onCheckPro">
              <a-radio :value="1">选择全部商品</a-radio>
              <a-radio :value="2">选择商品分类</a-radio>
              <a-radio :value="3">选择具体商品</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="信用额度"
          >
            <a-input-number
              v-model="rowData.limitAmount"
              :min="0"
              :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'"
            ></a-input-number>
            <br />
              <a-select
                v-if="classifyType === 2"
                mode="multiple"
                :size="size"
                placeholder="请选择分类"
                style="width: 600px"
                @popupScroll="popupScroll"
                v-model="initTableData"
                :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'"
              >
                <a-select-option v-for="item in tableData" :key="item.categoryId" @click='handleChange(item)'>
                  {{item.name}}
                </a-select-option>
              </a-select>
          <!-- <a-table v-if="classifyType === 2" :data-source="tableData" bordered>
            <a-table-column title="分类" align="center" data-index="name"></a-table-column>
              <a-table-column title="信用额度" align="center" data-index="limitAmount">
                <template slot-scope="text, row">
                  <a-input-number
                    v-model="row.limitAmount"
                    :min="0"
                    :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'"
                    @change="handlerChange(row)"
                  ></a-input-number>
                </template>
              </a-table-column>
            </a-table> -->
          </a-form-model-item>

          <a-form-model-item label="选择商品" v-if="classifyType === 3">
            <a-button :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'" type="primary" @click="onCheckProduct">添加商品</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <a-table v-if="dataList.length > 0" :data-source="dataList" bordered>
      <a-table-column title="商品名称" data-index="name" align="center"></a-table-column>
      <a-table-column title="商品图片" data-index="imgUrl" align="center"></a-table-column>
      <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
      <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
      <a-table-column title="商品价格" data-index="goodsPrice" align="center"> </a-table-column>
      <!-- <a-table-column title="信用额度" data-index="limitAmount" align="center" :width="150">
        <template slot-scope="text, row">
          <a-input-number
            :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'"
            v-model="row.limitAmount"
            :min="0"
          ></a-input-number>
        </template>
      </a-table-column> -->
      <a-table-column title="操作" data-index="" align="center">
        <template slot-scope="text, row">
          <a :disabled="handle == 'check' || handle == 'edit' || handle == 'checkCode'" @click="onDelete(row.goodsId)">删除</a>
        </template>
      </a-table-column>
    </a-table>
    <div class="footer-btns" v-if="handle != 'check' && handle != 'checkCode'">
      <a-button type="default" @click="toCancel">取消</a-button>
      <a-button type="primary" @click="toSubmit">提交</a-button>
    </div>
    <ProductChoose ref="ProductChoose" @get-pruduct-list="onAddProduct"></ProductChoose>
  </a-modal>
</template>

<script>
import SelectDealerModal from '../../common/SelectDealerModal.vue'
import { editLimitInfo, selectByIdLimitInfo, addLimitInfo, selectByCodeLimitInfo } from '../api/LimitInfoApi'
import moment from 'moment'
import ProductChoose from '@/views/product_tag/components/ProductChoose.vue'

export default {
  components: {
    ProductChoose,
    SelectDealerModal,
  },
  data() {
    return {
      selectDisabled:false,
      defaultType:[],
      size: 'default',
      dealerName: '',
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入额度名称', trigger: 'blur' }],
        validityStart: [{ required: true, message: '请选择有效期', trigger: 'change' }],
        fileUrl: [{ required: true, message: '请输入上传申请文件', trigger: 'change' }],
        limitType: [{ required: true, message: '请选择额度商品类型', trigger: 'change' }],
        limitAmount: [{ required: true, message: '请填写信用额度', trigger: 'blur' }],
      },
      tableData: [],
      newTableData:[],
      initTableData:[],
      classifyType: 0,
      dataList: [],
      categoryList: [],
      categorylist: [],
      autoExpandParent: true,
    }
  },
  computed: {},
  methods: {
    popupScroll() {
      console.log();
    },
    handleChange(data) {
      this.newTableData.push(data)
      console.log(JSON.parse(JSON.stringify(this.newTableData)));

    },
    init() {
      console.log(this.classifyType)
      this.classifyType = 1
    },
    // 计算总额度
    // handlerChange(row) {
    //   let limitAmount = 0
    //   if (this.classifyType === 2) {
    //     this.tableData.map((e) => {
    //       limitAmount = limitAmount + e.limitAmount;
    //     })
    //   } else if (this.classifyType === 3) {
    //     this.dataList.map((e) => {
    //       limitAmount = limitAmount + e.limitAmount;
    //     })
    //   }
    //   this.rowData.limitAmount = limitAmount;
    //   console.log(this.classifyType,'计算总额度:',limitAmount)
    // },

    toSelectConsumer(row) {
      this.$set(this.rowData, 'dealerId', row.id)
      this.$set(this.rowData, 'dealerName', row.name)
      this.$set(this.rowData, 'firmId', row.firmId)
      this.$set(this.rowData, 'firmName', row.firmName)
      this.$set(this.rowData, 'bigAreaId', row.areaId)
      this.$set(this.rowData, 'bigAreaName', row.area)
    },
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.$set(this.rowData, 'validityEnd', null)
      this.$set(this.rowData, 'validityStart', null)
      this.dataList = []
      this.tableData = []
      this.classifyType = 0
      if (handle === 'add') {
        this.$set(this.rowData, 'limitAmount', 0)
        this.getTreeList([])
        this.newTableData = []
        this.initTableData = []
      } else if (handle === 'edit' || handle === 'check') {
        selectByIdLimitInfo(row.id).then((res) => {
          this.rowData = res.body
          this.classifyType = res.body.limitType

          let list =
            res.body.goodsAmountList != null ? res.body.goodsAmountList : []
          this.categorylist = res.body.categoryAmountList != null ? res.body.categoryAmountList : []
          this.getTreeList(res.body.categoryAmountList)
          this.dataList = list.map((i) => {
            return {
              goodsId: i.goodsId,
              name: i.proName,
              url: i.imgUrl,
              goodsPrice: i.goodsPrice,
              brandName: i.bannerName,
              categoryName: i.cateName,
              limitAmount: i.limitAmount,
            }
          })
        })
      } else if (handle === 'checkCode') {
        selectByCodeLimitInfo(row.orderCode).then((res) => {
          this.rowData = res.body
          this.classifyType = res.body.limitType

          let list =
            res.body.goodsAmountList != null ? res.body.goodsAmountList : []
          this.categorylist = res.body.categoryAmountList != null ? res.body.categoryAmountList : []
          this.getTreeList(res.body.categoryAmountList)
          this.dataList = list.map((i) => {
            return {
              goodsId: i.goodsId,
              name: i.proName,
              url: i.imgUrl,
              goodsPrice: i.goodsPrice,
              brandName: i.bannerName,
              categoryName: i.cateName,
              limitAmount: i.limitAmount,
            }
          })
        })
      }
    },

    getTreeList(data) {
      this.getCategoryList(data)
    },

    getCategoryList(data) {
      this.axios.get('/api/product/category/parentCategoryList').then((res) => {
        let list = res.body
        this.tableData = list.map((e) => {
          return {
            categoryId: e.id,
            name: e.name,
            limitAmount: 0,
          }
        })
        if (this.handle == 'edit') {
          if (data != null) {
            this.initTableData = data.map((item) => {
              return item.categoryId
          })
          }
          // this.tableData = this.tableData.filter((ele, index) => {
          //   if (data && data[index] && data[index].categoryId) {
          //     return ele.categoryId == data[index].categoryId
          //     ? (ele.limitAmount = data[index].limitAmount)
          //     : 0
          //   }
          // })
        }
        if (this.handle == 'check' || this.handle == 'checkCode') {
          if (data != null) {
            this.initTableData = data.map((item) => {
              return item.categoryId
          })
          console.log(JSON.parse(JSON.stringify(this.newTableData)));
            // this.tableData = this.tableData.filter((ele, index) => {
            //   return data.find( prop => {
            //     return prop.categoryId == ele.categoryId ? (ele.limitAmount = prop.limitAmount) : 0
            //   })
            // })
          }
        }
      })
    },

    onCheckProduct() {
      this.$refs.ProductChoose.isShow()
    },

    disabledDate(current) {
      return current && current == moment()
    },

    onAddProduct(data) {
      let vo = []
      let arr = new Map()
      data.forEach((e) => {
        vo.push({
          goodsId: e.id,
          name: e.name,
          url: e.imgUrl,
          goodsPrice: e.sellPrice,
          brandName: e.brandName,
          categoryName: e.categoryName,
          limitAmount: 0,
        })
      })
      let list = this.dataList.concat(vo)
      this.dataList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))
    },

    // 删除商品
    onDelete(id) {
      this.dataList.splice(
        this.dataList.findIndex((e) => {
          return e.id === id
        }),
        1
      )
    },

    onCheckPro(val) {
      this.classifyType = val.target.value
    },
    toCancel() {
      this.visible = false
      this.$refs.form.resetFields()
      this.$emit('reload')
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if (this.classifyType === 1) {
          this.tableData = []
          this.dataList = []
        } else if (this.classifyType === 2) {
          this.dataList = []
          let list = this.tableData.filter((e) => e.limitAmount != 0)
          let limitAmount = 0
          this.rowData.categoryAmountList = list.map((e) => {
            limitAmount = limitAmount + e.limitAmount;
            return {
              ...e,
              limitAmount: e.limitAmount,
            }
          })
          this.rowData.categoryAmountList = this.newTableData;
          console.log(JSON.parse(JSON.stringify(this.rowData)),"this.rowData");
        } else if (this.classifyType === 3) {
          this.tableData = []
          let limitAmount = 0
          this.rowData.goodsAmountList = this.dataList.map((e) => {
            limitAmount = limitAmount + e.limitAmount;
            return {
              ...e,
              limitAmount: e.limitAmount,
            }
          })
        }
        if (this.rowData.limitType) {
          if (this.rowData.limitAmount == null || this.rowData.limitAmount == 0) {
            console.log(JSON.parse(JSON.stringify(this.rowData)),"this.rowData");
            return this.$notification.warning({ message: '请填写信用额度' })
          }
        }

        if (this.rowData.limitType === 2) {
          if (this.rowData.categoryAmountList.length <= 0) {
            return this.$notification.warning({ message: '请添加至少一个分类额度' })
          }
        }
        if (this.rowData.limitType === 3) {
          if (this.rowData.goodsAmountList.length <= 0) {
            return this.$notification.warning({ message: '请添加至少一个商品' })
          }
        }
        this.rowData.categoryAmountList = this.newTableData
        const res = this.handle === 'add' ? await addLimitInfo(this.rowData) : await editLimitInfo(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
</style>
