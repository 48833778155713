<template>
  <div class="product-choose">
    <a-modal
            title="选择商品"
            :visible="visibleTable"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
            class="product-choose-modal"
            width="73%"
            :maskClosable="false"
            >
            <a-input placeholder="请输入商品名称" v-model="searchData.name" style="width: 220px; margin-right: 20px" />
            <a-button type="primary" @click="getList(3)" icon="search">搜索</a-button>
            <a-button type="default" @click="getList(2)" icon="sync">重置</a-button>
            <a-table :scroll="{ x: 1400 }"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :columns="columnsChoose"
                :data-source="productList"
                :pagination="page"
                @change="changeTable"
                bordered>
                <span slot="name" slot-scope="text">
                  <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
                </span>
                <span slot="imgUrl" slot-scope="item">
                  <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
                </span>
            </a-table>
        </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        columnsChoose: [
          {
            title: 'CNC商号',
            dataIndex: 'cncCompany',
            width: 120,
            fixed: 'left',
            ellipsis:true,
          },
          {
            title: '商品名称',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            ellipsis:true,
            scopedSlots: { customRender: 'name'}
          },
          {
            title: '规格',
            dataIndex: 'specStr',
            ellipsis:true
          },
          {
            title: '总成号',
            dataIndex: 'assemblyNumber',
            ellipsis:true
          },
          {
            title: '适用品牌',
            dataIndex: 'carBrand',
            key: 'carBrand',
            align:'center',
            ellipsis:true
          },
          {
            title: '车型',
            dataIndex: 'carModel',
            key: 'carModel',
            align:'center',
            ellipsis:true,
          },
          {
            title: '商品图标',
            dataIndex: 'imgUrl',
            ellipsis:true,
            scopedSlots: { customRender: 'imgUrl' },
          },
          // {
          //   title: '车系',
          //   dataIndex: 'carSeries',
          //   key: 'carSeries',
          //   align:'center',
          //   ellipsis:true
          // },
          
          // {
          //   title: '排量',
          //   dataIndex: 'carDisplacement',
          //   key: 'carDisplacement',
          //   align:'center',
          //   ellipsis:true
          // },
          // {
          //   title: '图号',
          //   dataIndex: 'chartCount',
          //   key: 'chartCount',
          //   align:'center',
          //   ellipsis:true
          // },
          // {
          //   title: 'OE码',
          //   dataIndex: 'oeCode',
          //   key: 'oeCode',
          //   align:'center',
          //   ellipsis:true
          // },
          // {
          //   title: '分类',
          //   dataIndex: 'categoryName',
          //   ellipsis:true
          // },
          // {
          //   title: '库存',
          //   dataIndex: 'saleQuantity',
          //   ellipsis:true
          // },
        ],
        visibleTable: false,
        confirmLoading: false,
        productList: [],
        selectedRowKeys: [],
        options: [],
        searchData: {}, // 搜索条件
        item: [],
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 0,
        },
      }
    },
    computed: {
      hasSelected() {
        return this.selectedRowKeys.length > 0;
      },
    },
    methods: {
      isShow() {
        this.visibleTable = true
        this.item = []
        this.selectedRowKeys = []
        this.getList(1)
      },
      getList(type) {
        if(type == 2) {
          this.searchData = {}
        }
        if(type == 3) {
          this.page.pageNumber = 1
        }
        this.page.name = this.searchData.name
        this.axios.get('/api/product/system/goods/list?flagUp=1', {params: this.page}).then(res => {
          this.productList = res.body.records
          this.page.total = res.body.total
        })
      },
      showModal() {
        this.visibleTable = true;
        },
      handleOk(e) {
        this.$emit('get-pruduct-list', this.item)
        this.item = []
        this.visibleTable = false
      },
      handleCancel(e) {
        this.visibleTable = false;
      },
      start() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.selectedRowKeys = [];
        }, 1000);
      },
      changeTable(pagination) {
        this.page = pagination
        this.page.pageNumber = pagination.current
        this.getList(1)
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.item = selectedRows
      },
    }
  }
</script>

<style lang="scss">
  .product-choose {

  }
</style>
